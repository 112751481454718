<template>
  <div>
    <div class="page-title">
      <h3>Search Forms</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li class="active">
            Search
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-lg-offset-1 col-lg-10 col-md-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Form Results for <span class="text-danger">{{ searchedTerm }}</span><span
                  v-if="isLoading"
                  class="text-info"
                > (Loading...)</span>
              </h3>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="searchedFormsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th class="createdAt includeInExport">
                        Submission Date
                      </th>
                      <th
                        :class="{'includeInExport' : jobType === 'Owner'}"
                        class="createdAtTime"
                      >
                        Submission Time
                      </th>
                      <th class="bankVerificationDate includeInExport">
                        Bank Verification Date
                      </th>
                      <th class="city includeInExport">
                        Resident City
                      </th>
                      <th class="owner includeInExport">
                        Owner
                      </th>
                      <th class="supervisor includeInExport">
                        Supervisor
                      </th>
                      <th class="branchManager includeInExport">
                        Branch Manager
                      </th>
                      <th class="status includeInExport">
                        Status
                      </th>
                      <th class="statusJustifications includeInExport">
                        Status Justifications
                      </th>
                      <th class="referenceNumber includeInExport">
                        Reference Number
                      </th>
                      <th class="remarks includeInExport">
                        Remarks
                      </th>
                      <th class="offerType includeInExport">
                        Offer Type
                      </th>
                      <th class="product includeInExport">
                        Product
                      </th>
                      <th class="firstName includeInExport">
                        First Name
                      </th>
                      <th class="lastName includeInExport">
                        Last Name
                      </th>
                      <th class="pancard includeInExport">
                        Pancard
                      </th>
                      <th
                        :class="{'includeInExport' : jobType === 'Owner'}"
                        class="email"
                      >
                        Email
                      </th>
                      <th
                        :class="{'includeInExport' : jobType === 'Owner'}"
                        class="mobileNumber"
                      >
                        Mobile Number
                      </th>
                      <th
                        :class="{'includeInExport' : jobType === 'Owner'}"
                        class="updatedBy"
                      >
                        Last Updated By
                      </th>
                      <th
                        :class="{'includeInExport' : jobType === 'Owner'}"
                        class="updatedAt"
                      >
                        Updated On
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AIPFormModal
        :form-details="formDetails"
        :job-type="jobType"
        :report-only="reportOnly"
        :show-history="true"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import AIPFormModal from "../../components/AIPFormModal";
import {API, graphqlOperation} from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";

export default {
  name: "SearchForms",
  components: {
    AIPFormModal
  },
  data() {
    return {
      jobType: '',
      formsTable: null,
      tableData: [],
      formDetails: {},
      searchedTerm: '',
      isLoading: false
    }
  },
  beforeRouteLeave(to, from, next) {
    let formModal = $("#formDetailsModal");
    if ((formModal.data('bs.modal') || {}).isShown) {
      formModal.modal('hide');
      document.getElementById('searchedFormsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getStatuses",
      "getTitles",
      "getProducts",
      "getUsers",
      "getSearchFormTerm"
    ]),
    ...mapState([
      'loggedInUser',
      'searchFormTerm',
      'updateSubscriptionForm',
      'deleteSubscriptionForm'
    ]),
    reportOnly: function () {
      return _.includes(['User', 'Supervisor', 'Manager', 'Partner', 'Owner'], this.jobType);
    }
  },
  watch: {
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
          }
        }
      }
    },
    searchFormTerm(newValue) {
      $('#search-form-term')[0].value = newValue;
      if (newValue && newValue !== '') {
        this.isLoading = true;
        this.tableData = [];
        this.searchedTerm = newValue;
        this.fetchForms().then(() => {
          this.isLoading = false;
          this.UPDATE_SEARCH_FORM_TERM('');
        });
      }
    },
    tableData() {
      this.updateFormsTableData();
    },
    updateSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.tableData);
      let formIndex = _.findIndex(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      if (formIndex !== -1) {
        this.formsTable.row("#" + newValue.id)
            .invalidate()
            .data(newValue)
            .draw();
        oldForms[formIndex] = newValue;
        this.tableData = oldForms;
      }
    },
    deleteSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.tableData);
      _.remove(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      this.formsTable.row("#" + newValue.id)
          .remove()
          .draw();
      this.tableData = oldForms;
    },
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
      }
    }
    if (this.getSearchFormTerm && this.getSearchFormTerm !== '') {
      this.isLoading = true;
      this.tableData = [];
      this.searchedTerm = this.getSearchFormTerm;
      this.fetchForms().then(() => {
        this.UPDATE_SEARCH_FORM_TERM('');
        this.isLoading = false;
      });
    }
    this.createDatatable();
  },
  methods: {
    ...mapMutations([
      "UPDATE_SEARCH_FORM_TERM",
    ]),
    async fetchForms(nextPage = null) {
      let filter = {
        or: [
          //TODO: fuzzy search not currently supported by amplify (https://github.com/aws-amplify/amplify-cli/issues/799)
          {firstName: {wildcard: this.searchedTerm + '*'}},
          {lastName: {wildcard: this.searchedTerm + '*'}},
          {nameDesired: {wildcard: this.searchedTerm + '*'}},
          {email: {match: this.searchedTerm}},
          {emailOfficial: {match: this.searchedTerm}},
          {pancard: {match: this.searchedTerm}},
          {mobileNumber: {match: this.searchedTerm}},
          {mobileNumberAlternate: {match: this.searchedTerm}},
          {officeLandline: {match: this.searchedTerm}},
          {appId: {match: this.searchedTerm}},
          {referenceNumber: {match: this.searchedTerm}},
          {owner: {match: this.searchedTerm}}
        ]
      };
      if (this.jobType === 'User') {
        filter.owner = {
          eq: this.getLoggedInUser.username
        };
      } else if (this.jobType === 'Supervisor') {
        filter.supervisor = {
          eq: this.getLoggedInUser.username
        };
      } else if (this.jobType === 'Manager') {
        filter.branchManager = {
          eq: this.getLoggedInUser.username
        };
      } else if (this.jobType === 'BackOffice') {
        filter.branchId = {
          eq: this.getLoggedInUser.branchId[0]
        };
      }
      if (this.getLoggedInUser.products && this.getLoggedInUser.products.length > 0) {
        filter.product = {
          eq: this.getLoggedInUser.products[0]
        };
      }
      const {
        data: {
          searchForms: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(customQueries.searchFormsWithHistoryAllFields, {
        filter: filter,
        limit: 999,
        nextToken: nextPage
      }));
      this.tableData = _.uniqBy(_.concat(this.tableData, items), 'id');
      nextPage = nextToken;
      if (nextPage) {
        this.fetchForms(nextPage);
      }
    },
    createDatatable: function () {
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        }
      ];
      if (this.jobType === 'BackOffice') {
        buttons.push(
            {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel',
              exportOptions: {
                columns: '.includeInExport'
              }
            }
        );
      } else if (this.jobType === 'Owner') {
        buttons.push(
            {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel'
            }
        );
      }
      this.formsTable = $('#searchedFormsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.tableData,
        rowId: 'id',
        columns: [
          {
            data: 'createdAt'
          },
          {
            data: 'createdAt'
          },
          {
            data: 'bankVerificationDate',
            defaultContent: ""
          },
          {
            data: 'city',
            defaultContent: ""
          },
          {
            data: 'owner',
            defaultContent: ""
          },
          {
            data: 'supervisor',
            defaultContent: ""
          },
          {
            data: 'branchManager',
            defaultContent: ""
          },
          {
            data: 'status',
            defaultContent: ""
          },
          {
            data: 'statusJustifications',
            defaultContent: ""
          },
          {
            data: 'referenceNumber',
            defaultContent: ""
          },
          {
            data: 'remarks',
            defaultContent: ""
          },
          {
            data: 'offerType',
            defaultContent: ""
          },
          {
            data: 'product',
            defaultContent: ""
          },
          {
            data: 'firstName'
          },
          {
            data: 'lastName'
          },
          {
            data: 'pancard'
          },
          {
            data: 'email',
            defaultContent: ""
          },
          {
            data: 'mobileNumber',
            defaultContent: ""
          },
          {
            data: 'updatedBy',
            defaultContent: ""
          },
          {
            data: 'updatedAt',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('DD-MM-YYYY');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: ['createdAt', 'bankVerificationDate']
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('h:mm A');
              } else {
                return '';
              }
            },
            targets: "createdAtTime"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: ["owner", "supervisor", "branchManager", "updatedBy"]
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Status');
            },
            targets: "status"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Array');
            },
            targets: "statusJustifications"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: "product"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Date');
            },
            targets: "updatedAt"
          },
          {
            visible: false,
            targets: ["bankVerificationDate", "supervisor", "branchManager", "referenceNumber", "remarks", "offerType", "product", "email", "mobileNumber", "updatedAt"]
          }
        ],
        buttons: buttons,
        searching: false,
        order: [[0, "desc"], [1, "desc"]]
      });

      this.formsTable.buttons()
          .container()
          .appendTo('#searchedFormsList_wrapper .col-sm-6:eq(0)');

      this.setupFormModal();
    },
    updateFormsTableData: function () {
      this.formsTable.clear().draw();
      this.formsTable.rows.add(this.tableData);
      this.formsTable.columns.adjust().draw();
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      } else if (type === 'Product') {
        return _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name') || '';
      } else if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      } else if (type === 'Array') {
        return _.join(value, ', ');
      }
    },
    setupFormModal: function () {
      $('#searchedFormsList tbody').on('click', 'tr', (e) => {
        this.formDetails = this.formsTable.row(e.currentTarget).data();
        if (this.formDetails) {
          $("#formDetailsModal").modal('show');
        } else {
          this.formDetails = {};
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
